<template>
    <main>
        <section>
            <div class="logo">
                <a href="/">
                    <img :src="require(`@/assets/${smallLogos.logo}`)" alt="" />
                </a>
            </div>
            <nav>
                <ul class="flex">
                    <li>
                        <a href="#Mission">Mission</a>
                    </li>
                    <li>
                        <a href="#Products">Products</a>
                    </li>
                    <li>
                        <a href="#Timeline">Timeline</a>
                    </li>
                    <li>
                        <a href="https://blog.openrelay.xyz/">Blog</a>
                    </li>
                    <li>
                        <a @click="passFunct" href="#Team">Team</a>
                    </li>
                </ul>
            </nav>
            <rivetMenuBtnVue @openNav="openNavBar" />
        </section>
    </main>
</template>

<script>
import rivetMenuBtnVue from "./rivet-menu-btn.vue";
import images from "../exports/media";
export default {
    components: { rivetMenuBtnVue },
    name: "heading",
    data() {
        return {
            smallLogos: images.smallLogos,
        };
    },
    methods: {
        passFunct() {
            this.$emit("passFunction");
        },
        openNavBar() {
            this.$emit("openMenu");
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    top: 0;
    padding-top: 20px;
    z-index: 70;
    background: white;
    section {
        @include flex(space-between, center, center);
        @include media("<=phone-tab") {
            width: 90%;
        }
        .logo {
            width: 4.5rem;
            img {
                width: 100%;
            }
        }
        nav {
            width: 40%;
            @include media("<=tablet") {
                display: none;
            }
            ul {
                justify-content: space-between;
                li {
                    font-family: inherit;
                    font-size: 1.2em;
                    a {
                        color: #383757;
                    }
                }
            }
        }
    }
}
</style>
