<template>
    <div class="riv_menu" @click="toggleMenubar">
        <p>Menu</p>
        <div class="buttons">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from "vue";
const emit = defineEmits(["openNav"]);
const toggleMenubar = () => {
    document.querySelector(".riv_menu .buttons").classList.toggle("open");
    document.querySelector(".riv_menu").classList.toggle("open");
    emit("openNav");
};
</script>

<style lang="scss" scoped>
.riv_menu.open {
    p {
        color: white !important;
    }
    border-color: white !important;
}

.riv_menu {
    transition: 0.5s ease-out;
    display: none;
    z-index: 100;
    @include media("<=tablet") {
        @include flex(space-between, center, 1rem);
    }
    p {
        font-weight: 900;
        font-size: 1.2em;
    }

    padding: 0.4rem 1.2rem;
    border: 1px solid black;
    .buttons {
        @include flex_col(0.3rem);
        span {
            height: 0.1rem;
            width: 1.5rem;
            background: black;
            transition: 0.5s ease-out;
        }
    }
    .buttons.open {
        span {
            background: white;
            &:nth-child(1) {
                transform: rotateZ(43deg) translateY(6.5px);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotateZ(-42deg) translateY(-8px);
            }
        }
    }
}
</style>
