<template>
    <main>
        <section>
            <div class="text">
                <h2 data-animation="paragraph">Want more information about us or what we do?</h2>
            </div>
            <div class="btn">
                <a href="mailto:info@openrelay.xyz">
                    <button>Send a message</button>
                </a>
            </div>
        </section>
    </main>
</template>

<style lang="scss" scoped>
main {
    padding: 5rem 0px;
    background: #2901c2;
    section {
        @include flex(space-between, center, center);
        @include media("<=tablet") {
            @include flex_col(3rem);
            align-items: flex-start;
        }
        .text {
            width: 50%;
            @include media("<=tablet") {
                width: 100%;
            }
            h2 {
                font-size: 3.2em;
                line-height: 3.5rem;
                color: white;
                @include media("<=phone-tab") {
                    font-size: 3em;
                }
            }
        }
        .btn {
            button {
                font-size: 1.5em;
                color: white;
                border: 1px solid white;
                background: transparent;
                border-radius: 9px;
                padding: 1.3rem 3.5rem;
            }
        }
    }
}
</style>
