<template>
    <main id="Team">
        <section>
            <div class="begin">
                <h2 data-animation="heading">Team</h2>
                <p data-animation="paragraph">Each of the people who contributed to the creation of the world class organization OpenRelay is</p>
            </div>
            <div class="img_grid grid">
                <div v-for="indiv in data" class="indiv" :key="indiv.id">
                    <div class="img">
                        <a v-if="indiv.url != false" :href="indiv.url">
                            <img :src="require(`@/assets/${indiv.img}`)" alt="" />
                        </a>
                        <img v-else :src="require(`@/assets/${indiv.img}`)" alt="" />
                    </div>

                    <a v-if="indiv.url != false" :href="indiv.url">
                        <h3 data-animation="paragraph">{{ indiv.name }}</h3>
                    </a>
                    <h3 data-animation="paragraph" v-else>{{ indiv.name }}</h3>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import db from "../data/data.json";
export default {
    data() {
        return {
            data: db.Team,
        };
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 80px 0px;
    background: #5415c6;
    section {
        @include flex_col(3rem);
        .begin {
            h2,
            p {
                color: white;
            }
        }
        .img_grid {
            grid-template-columns: repeat(4, 1fr);
            gap: 2rem 4em;
            width: 90%;
            .indiv {
                text-align: center;
                h3 {
                    color: white;
                    font-size: 0.98em;
                    font-weight: 400;
                    margin: 20px 0px;
                }
                .img {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: 765px) {
    main {
        section {
            .img_grid {
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
            }
        }
    }
}
@media (max-width: 500px) {
    main {
        section {
            .img_grid {
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
            }
        }
    }
}
</style>
