<template>
    <div class="wrap containment">
        <transition enter-active-class=" animate__animated animate__slideInRight" leave-active-class="animate__animated animate__slideOutRight ">
            <navbar @closeMenu="promptOpen" v-if="navOn" />
        </transition>
        <Header @openMenu="promptOpen" />
        <landing />
        <tierone />
        <values />
        <products />
        <team />
        <timeline />
        <Blog />
        <message />
        <Footer />
    </div>
</template>

<script>
import "animate.css";
import Header from "./components/header.vue";
import Landing from "./components/landing.vue";
import tierone from "./components/m&v.vue";
import values from "./components/values.vue";
import Products from "./components/products.vue";
import Timeline from "./components/timeline.vue";
import Team from "./components/team.vue";
import message from "./components/message.vue";
import Blog from "./components/blog.vue";
import Footer from "./components/footer.vue";
import Navbar from "./components/menu_bar.vue";
export default {
    methods: {
        promptOpen() {
            this.navOn = !this.navOn;
        },
    },
    data() {
        return {
            navOn: false,
            valueOfTl: false,
        };
    },
    components: {
        Header,
        Landing,
        tierone,
        values,
        Products,
        Team,
        message,
        Footer,
        Blog,
        Timeline,
        Navbar,
    },
};
</script>
