<template>
    <main>
        <section>
            <div class="begin">
                <h2>Blog</h2>
                <p>Our blog will keep you up-to-date with information on the latest events and offers from us.</p>
            </div>
            <div v-if="rssFeed" class="blog_wrap flex">
                <div class="blog_item">
                    <a :href="rssFeed.items[0].link">
                        <h3>{{ rssFeed.items[0].title }}</h3>
                    </a>
                    <div class="text" v-html="rssFeed.items[0].description.substring(0, 400)"></div>
                    <p class="date">{{ rssFeed.items[0].pubDate }}</p>
                </div>
                <div class="blog_item">
                    <a :href="rssFeed.items[1].link">
                        <h3>{{ rssFeed.items[1].title }}</h3>
                    </a>
                    <div class="text" v-html="rssFeed.items[1].description.substring(0, 400)"></div>
                    <p class="date">{{ rssFeed.items[1].pubDate }}</p>
                </div>
            </div>
            <div v-else class="blog_wrap flex">
                <div class="blog_item">
                    <a href="https://blog.openrelay.xyz">
                        <h3>A Framework for Understanding Vulnerability Lifecycles in Ethereum Clients.</h3>
                    </a>
                    <div class="text">
                        <p>
                            It follows that they would have updated much sooner if they could have had confidence that an update would bring bug fixes
                            without instability and critical breaking issues that negatively impact their users.
                        </p>
                    </div>
                    <p class="date">21 Jul 2021</p>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    data() {
        return {
            rssFeed: "",
        };
    },
    mounted() {
        this.fetchJson().then((data) => (this.rssFeed = data));
    },
    methods: {
        async fetchJson() {
            let response = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.openrelay.xyz%2Ffeed.xml");
            let data = await response.json();
            return data;
        },
    },
};
</script>

<style lang="scss" scoped>
blockquote {
    size: inherit;
}
main {
    padding: 100px 0px;
    section {
        @include flex_col(2.5em);
        .blog_wrap {
            align-items: normal;
            @include media("<=tablet") {
                @include flex_col(2rem);
            }
            .blog_item {
                cursor: pointer;
                min-height: 26rem;
                transition: 0.5s ease-in-out;
                &:hover {
                    border-color: #5032c7;
                }
                width: 48%;
                padding: 30px;
                border: 2px solid #ebeaed;
                border-radius: 10px;
                @include media("<=tablet") {
                    width: 100%;
                }
                h2 {
                    font-size: 1.5em;
                }
                .text {
                    margin: 30px 0px;
                }
                .date {
                    color: rgba(21, 20, 57, 0.4);
                    font-size: 0.9em;
                }
            }
        }
    }
}
</style>
