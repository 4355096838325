<template>
    <main id="Products">
        <section>
            <div class="begin">
                <h2 data-animation="heading">Products</h2>
                <p data-animation="paragraph">
                    Our current products serve as an example of what we do best. Our talent and skills are dedicated to developing even better
                    technology.
                </p>
            </div>
            <div class="prod_wrap">
                <div v-for="product in products" :key="product.id" class="contain">
                    <div class="copy">
                        <h3 data-animation="heading">
                            {{ product.name }}
                        </h3>
                        <p data-animation="paragraph">{{ product.info }}</p>
                        <div class="btn">
                            <a :href="product.url">
                                <button>Learn more</button>
                            </a>
                        </div>
                    </div>
                    <div class="img">
                        <img
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-easing="ease-out"
                            loading="lazy"
                            data-aos-mirror="false"
                            data-aos-once="true"
                            :src="require(`@/assets/${product.img}`)"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import product_data from "../data/data.json";
export default {
    data() {
        return {
            products: product_data.products.reverse(),
        };
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 6rem 0;
    overflow-x: hidden;
    section {
        @include flex_col(5rem);
        .prod_wrap {
            @include flex_col(4rem);
            .contain {
                overflow-x: hidden;
                @include flex(space-between, center, center);
                @include media("<=tablet") {
                    @include flex_col(3rem);
                    width: 100%;
                }
                &:nth-child(2) {
                    flex-direction: row-reverse;
                }
                .copy {
                    width: 45%;
                    @include media("<=tablet") {
                        width: 100%;
                    }
                    h3 {
                        font-size: 2.2em;
                    }
                    .btn {
                        margin: 30px 0px;
                        button {
                            padding: 1rem 5rem;
                            border: 2px solid #bfb3ed;
                            border-radius: 0.4rem;
                            background: white;
                            color: #2901c2;
                            font-family: inherit;
                            font-weight: normal;
                            font-size: 1.3em;
                        }
                    }
                }
                .img {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
