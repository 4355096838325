<template>
    <main>
        <section>
            <div class="left">
                <h1 data-animation="header">
                    We are creating the <span class="c-text">foundation</span> for the <span class="c-text1">new</span> Web.
                </h1>
                <p data-animation="paragraph">
                    Scalable. Open source. Reliable. We’re building the future of infrastructure for the decentralized web, from IPFS to Ethereum...
                </p>
                <div class="product_img flex">
                    <div class="img">
                        <a href="">
                            <img :src="require(`@/assets/${logo.plugeth}`)" target="_blank" alt="https://github.com/openrelayxyz/plugeth" />
                        </a>
                    </div>
                    <div class="img">
                        <a href="https://rivet.cloud">
                            <img target="_blank" :src="require(`@/assets/${logo.rivet}`)" alt="" />
                        </a>
                    </div>
                    <div class="img">
                        <a target="_blank" href="https://github.com/openrelayxyz/cardinal-evm">
                            <img :src="require(`@/assets/${logo.cardinal}`)" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div ref="right_animation" class="right flex">
                <svg height="400" viewBox="0 0 900 900" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 2">
                        <g id="two">
                            <path
                                id="Vector"
                                d="M452.675 805.198C256.932 805.198 97.6805 645.87 97.6805 450.23H195.719C195.719 501.056 210.791 550.741 239.028 593.001C267.266 635.262 307.4 668.2 354.358 687.65C401.315 707.101 452.986 712.189 502.835 702.274C552.685 692.358 598.474 667.883 634.413 631.944C670.353 596.004 694.828 550.214 704.744 500.365C714.659 450.515 709.571 398.845 690.12 351.888C670.67 304.931 637.732 264.796 595.471 236.558C553.211 208.321 503.527 193.249 452.701 193.249V95.3394C648.443 95.3394 807.694 254.668 807.694 450.333C807.694 645.999 648.418 805.198 452.675 805.198Z"
                                fill="#FF66D8"
                            />
                        </g>
                        <g id="one">
                            <path
                                id="Vector_2"
                                d="M453.519 707.573C385.207 707.573 319.692 680.436 271.388 632.132C223.084 583.828 195.947 518.313 195.947 450.001C195.947 381.689 223.084 316.174 271.388 267.87C319.692 219.566 385.207 192.429 453.519 192.429V290.467C411.208 290.467 370.63 307.275 340.712 337.193C310.793 367.112 293.986 407.69 293.986 450.001C293.986 492.312 310.793 532.89 340.712 562.809C370.63 592.727 411.208 609.535 453.519 609.535V707.573Z"
                                fill="#92DCE5"
                            />
                        </g>
                        <g id="three">
                            <path
                                id="Vector_3"
                                d="M450 900V802.038C644.073 802.038 802.065 644.149 802.065 449.974C802.065 255.799 644.175 97.9098 450 97.9098C255.825 97.9098 97.9359 255.799 97.9359 449.974H0C0 201.859 201.885 0 450 0C698.116 0 900 201.859 900 449.974C900 698.09 698.116 900 450 900Z"
                                fill="#2901C2"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    </main>
</template>

<script>
import images from "../exports/media";
import { gsap, Circ } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
    data() {
        return {
            anime: images.geometric,
            logo: images.smallLogos,
        };
    },
    methods: {
        animteOnEnter() {
            this.$refs.right_animation.querySelector("#one").style = "animation-play-state:initial";
            this.$refs.right_animation.querySelector("#three").style = "animation-play-state:initial";
            this.$refs.right_animation.querySelector("#two").style = "animation-play-state:initial";
        },
        animteOnLeave() {
            this.$refs.right_animation.querySelector("#one").style = "animation-play-state:paused";
            this.$refs.right_animation.querySelector("#three").style = "animation-play-state:paused";
            this.$refs.right_animation.querySelector("#two").style = "animation-play-state:paused";
        },
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        gsap.set(".right svg g", {
            animationPlayState: "paused",
        });
        gsap.to(".right svg", {
            scrollTrigger: {
                start: "+=50 top",
                trigger: ".right",
                onEnterBack: this.animteOnEnter,
                onEnter: this.animteOnEnter,
                onLeaveBack: this.amimteOnLeave,
                onLeave: this.animteOnLeave,
            },
        });

        gsap.from(".product_img img", {
            opacity: 0,
            stagger: 0.3,
            ease: Circ.easeIn,
        });
    },
};
</script>

<style lang="scss" scoped>
$fblue: #5415c6;
$light: #91dce5;
$blue: #2b02c3;
.c-text {
    color: $light;
}
.c-text1 {
    color: $fblue;
}
#three {
    animation: rotate 5s forwards linear infinite;
    transform-origin: center;
}
#two {
    animation: rotateLeft 10s forwards linear infinite;
    transform-origin: center;
}
#one {
    animation: rotateRight 7s forwards linear infinite;
    transform-origin: center;
}
main {
    section {
        padding: 7rem 0px;
        @include flex(space-between, center, center);
        @include media("<=tablet") {
            @include flex_col(8rem);
        }
        @include media("<=phone-tab") {
            padding: 5rem 0;
        }
        .left {
            width: 55%;
            @include media("<=tablet") {
                width: 100%;
            }
            @include flex_col(3rem);
            gap: 2.4rem 0;
            p {
                width: 90%;
                font-size: 1.4em;
            }
            h1 {
                span {
                    line-height: 10px;
                    overflow: hidden;
                }
            }

            .product_img {
                width: 50%;
                @include media("<=phone-tab") {
                    width: 80%;
                }
                .img {
                    img {
                        &:hover {
                            transform: translateY(-8px);
                            transition: 0.6s ease-in-out;
                        }
                    }
                }
            }
        }
        .right {
            width: 35%;
            @include media("<=tablet") {
                width: 100%;
            }
            svg {
                width: 100%;
            }
        }
    }
}
@keyframes rotate {
    from {
        transform: rotateZ(0px);
    }
    to {
        transform: rotateZ(360deg);
    }
}
@keyframes rotateLeft {
    from {
        transform: rotateZ(0px);
    }
    to {
        transform: rotateZ(-360deg);
    }
}
@keyframes rotateRight {
    from {
        transform: rotate(0px);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
